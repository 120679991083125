import React, { Component } from 'react';


class Calendar extends Component {
    state = {}

    componentDidMount() {
        this.createCalendar();
    }

    createCalendar() {
        const year = 2024; // Hier setze dein gewünschtes Jahr
        const month = 2; // Hier setze deinen gewünschten Monat (0 für Januar, 1 für Februar, usw.)

        // Erstellen des Datumobjekts für den ersten Tag des Monats
        const firstDayOfMonth = new Date(year, month, 1);
        // Wochentag des ersten Tages (0 = Sonntag, 1 = Montag, ..., 6 = Samstag)
        let firstWeekDay = firstDayOfMonth.getDay();
        // Falls der erste Tag ein Sonntag ist, setze ihn auf 7 (entspricht dem letzten Tag der Woche)
        firstWeekDay = firstWeekDay === 0 ? 7 : firstWeekDay;

        // Anzahl der Tage im Monat berechnen
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        // Referenz zur Tabelle erhalten
        const calendarTable = document.getElementById('calendar').getElementsByTagName('tbody')[0];

        // Index für den aktuellen Tag im Monat
        let dayIndex = 1;

        // Schleife für die Zeilen des Kalenders
        for (let i = 0; i < 6; i++) {
            // Neue Zeile erstellen
            const newRow = calendarTable.insertRow();

            // Schleife für die Zellen der aktuellen Zeile
            for (let j = 0; j < 7; j++) {
                // Neue Zelle erstellen
                const newCell = newRow.insertCell();

                // Falls es sich um den ersten Tag des Monats oder später handelt und noch nicht alle Tage des Monats hinzugefügt wurden
                if (i === 0 && j >= firstWeekDay - 1 || i > 0 && dayIndex <= daysInMonth) {
                    // Datum für die Zelle erstellen
                    const currentDate = new Date(year, month, dayIndex);

                    // Tag in die Zelle einfügen
                    newCell.textContent = dayIndex;

                    // ID für die Zelle erstellen
                    newCell.id = `${year}${month.toString().padStart(2, '0')}${dayIndex.toString().padStart(2, '0')}cell`;
                    newCell.className = 'day-cell';
                    // Platz für Benutzereinträge hinzufügen
                    const entrySpace = document.createElement('div');
                    // Erstellen der ID für das div
                    entrySpace.id = `${year}${month.toString().padStart(2, '0')}${dayIndex.toString().padStart(2, '0')}user`;
                    entrySpace.className = 'entry-space';
                    // Festlegen der Höhe für den DIV-Container
                    entrySpace.style.height = '50px'; // Hier die gewünschte Höhe in Pixeln
                    newCell.appendChild(entrySpace);

                    // Nächsten Tag im Monat erhöhen
                    dayIndex++;
                }
            }
        }

        // Control Panel HTML
        const controlPanelHTML = `
    
        <div className="control-panel">
            <div className="row">
                <div className="col 1">
                    <select className="form-select" name="betreuer" id="betreuer">
                        <option value="leer">Leer</option>
                        <option value="omaopa">Oma & Opa</option>
                        <option value="lisa">Lisa</option>
                        <option value="andre">André</option>
                    </select>
                </div>
                
                <div className="col 1">
                    <button className ="btn btn-primary">Speichern</button>
                </div>
            </div>
        </div>`;

        // Control Panel zum Dokument hinzufügen
        document.body.insertAdjacentHTML('beforeend', controlPanelHTML);

        var selectedValue = 'leer';
        // Event-Listener für das Dropdown-Menü hinzufügen
        document.getElementById('betreuer').addEventListener('change', function () {
            selectedValue = this.value;
            console.log(selectedValue);
        });


        // Auswahl aller Elemente mit der Klasse 'entry-space'
        const entrySpaces = document.getElementsByClassName('entry-space');
        // Auswahl aller Elemente mit der Klasse 'day-cell'
        const dayCells = document.getElementsByClassName('day-cell');

        // Iteration über alle Zellen und Hinzufügen eines Click-Event-Listeners
        for (let i = 0; i < entrySpaces.length; i++) {
            entrySpaces[i].style.backgroundColor = '#f5f5f5';
        }


        // Iteration über alle Zellen und Hinzufügen eines Click-Event-Listeners zum färben der day-cell
        for (let i = 0; i < dayCells.length; i++) {
            const cell = dayCells[i];
            cell.addEventListener('click', function () {
                let cellID = cell.id;
                if (selectedValue === 'leer') {
                    console.log(cellID);
                    let entryID = cellID.substring(0, 8) + 'user';
                    console.log(entryID);
                    let entrySpace = document.getElementById(entryID);
                    console.log(cellID);
                    cell.style.backgroundColor = 'white';
                } else if (selectedValue === 'omaopa') {
                    cell.style.backgroundColor = 'lightgoldenrodyellow';
                } else if (selectedValue === 'lisa') {
                    cell.style.backgroundColor = 'lightblue';
                } else if (selectedValue === 'andre') {
                    cell.style.backgroundColor = 'lightgreen';
                }

            })

        }


        console.log(selectedValue);

        console.log('Kalender wurde erstellt.');
    }

    render() {
        return (<div>

            <table id="calendar" className="calendar">
                <thead>
                    <tr>
                        <th>Mo</th>
                        <th>Di</th>
                        <th>Mi</th>
                        <th>Do</th>
                        <th>Fr</th>
                        <th>Sa</th>
                        <th>So</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>

        </div>
        );
    }
}

export default Calendar;
