import React, { Component } from 'react';
import Home from './home';


class Cards extends Component {
    state = {}
    render() {
        return <React.Fragment>

            <div className='content-container'>
                <Home title="Shop" image="shop.jpg" description="Hier entsteht ein Web-Shop" button="Shoppen" route="/shop" />
                <Home title="Kalender" image="kalender.jpg" description="Offizieller Enkalender" button="Los geht's!" route="/bs_kalender" />
            </div>
        </React.Fragment>;
    }
}

export default Cards;